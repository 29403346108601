<template>
    <div class="bg-white py-8 sm:py-8">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:text-center">
                <h1 class="text-base font-semibold leading-7 text-indigo-600">Emoji Search</h1>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A simple yet super useful
                    emoji search.</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">Search the emoji you want, click to copy to your
                    clipboard.<br>Its that simple</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                    Emojis are not just fun; they are also a powerful way to enhance your communication. They help clarify your tone, prevent misunderstandings, and make your text conversations much more engaging. Emojis are a universal language that transcends borders, making your communications more inclusive and expressive.
                </p>    
            </div>
        </div>
        
    </div>
    <!-- component -->

    <nav class="bg-white sticky top-[100vh]">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-center">
                <div class="flex items-center">
                    <div class="md:block">
                        <div class="ml-10 flex items-baseline space-x-4">
                            Built with ❤ by <a href="https://macromanhq.com" target="_blank" class="p-1 text-gray-800">
                                Macroman Solution </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'FooterBar',
}
</script>